var NerivonAutocomplete = {};

NerivonAutocomplete.initialize = function()
{
	jQuery(".autocomplete-simple").each(function()
	{
		var obj = jQuery(this);
		simple_autocomplete(obj.attr("id"), obj.attr("data-source"));
	});

	jQuery(".autocomplete-combo").each(function()
	{
		var obj = jQuery(this);
		combo_autocomplete(obj.attr("id"), obj.attr("data-source"));
	});
};

jQuery(document).ready(function()
{
	NerivonAutocomplete.initialize();
});

$.widget( "custom.catcomplete", $.ui.autocomplete,
{
    _create: function()
    {
      	this._super();
      	this.widget().menu( "option", "items", "> :not(.ui-autocomplete-category)" );
    },
    _renderMenu: function( ul, items )
    {
      	var that = this, currentCategory = "";

      	jQuery.each(items, function(index, item)
      	{
      		var li;
	        if(item.category != undefined && item.category != currentCategory)
	        {
	        	ul.append( "<li class='ui-autocomplete-category'>" + item.category + "</li>" );
	          	currentCategory = item.category;
	        }
	        li = that._renderItemData(ul, item);
	        if(item.category != undefined)
	        {
	        	li.attr("aria-label", item.category + " : " + item.label);
	        }
	    });
    }
});

function simple_autocomplete(field_id, source)
{
	var original_id 	= "";
	var original_text 	= "";

	// Attempt to remove any existing autocomplete handlers.
	try
	{
		jQuery("#" + field_id + "_autocomplete").catcomplete("destroy");
	}
	catch(e){}

	// Attach auto complete handler.
	jQuery("#" + field_id + "_autocomplete").catcomplete(
	{
		source: source,
		minLength: 0,
		delay: 0,
		autoFocus: false,
		create: function( event, ui )
		{
			original_id 	= jQuery("#" + field_id).val();
			original_text 	= jQuery("#" + field_id + "_autocomplete").val();
		},
		select: function( event, ui )
		{
			if(ui.item.id != "")
			{
				jQuery("#" + field_id).val(ui.item.id);
			}
			else
			{
				jQuery("#" + field_id).val("");
			}

			// Manually fire change event in case anything is listening.
			setTimeout(function()
			        	{
			           		jQuery("#" + field_id).change();
							jQuery("#" + field_id + "_autocomplete").change();
						}, 10);
		},
		close: function( event, ui )
		{
			// if(jQuery("#" + field_id).val() == "")
			// {
			// 	// Search aborted - restore original values
			// 	jQuery("#" + field_id).val(original_id);
			// 	jQuery(this).val(original_text).change();
			// }
			// else
			// {
			// }

			// Manually fire change event in case anything is listening.
			setTimeout(function()
			        	{
			           		jQuery("#" + field_id).change();
							jQuery("#" + field_id + "_autocomplete").change();
						}, 10);
		},
		change: function( event, ui )
		{
			if(jQuery("#" + field_id).val() == "")
			{
				jQuery("#" + field_id).val(original_id);
				jQuery(this).val(original_text);

				// Manually fire change event in case anything is listening.
				setTimeout(function()
				        	{
				           		jQuery("#" + field_id).change();
								jQuery("#" + field_id + "_autocomplete").change();
							}, 10);
			}
		},
		search: function( event, ui )
		{
			// Temporarily wipe the ID so we can tell if anything was selected later.
			// jQuery("#" + field_id).val("").change();
		},
		_create: function()
		{
      		this._super();
      		this.widget().menu( "option", "items", "> :not(.ui-autocomplete-category)" );
    	},
    	_renderMenu: function( ul, items )
    	{
    		var that = this,
        	currentCategory = "";
      		$.each( items, function( index, item )
      		{
        		var li;
        		if ( item.category != currentCategory )
        		{
          			ul.append( "<li class='ui-autocomplete-category'>" + item.category + "</li>" );
          			currentCategory = item.category;
        		}
        		li = that._renderItemData( ul, item );

        		if ( item.category )
        		{
          			li.attr( "aria-label", item.category + " : " + item.label );
        		}
      		});
      	}
	});

	// Bind the text box so that it opens the menu automatically on focus.
	jQuery("#" + field_id + "_autocomplete").unbind("focus").focus(function(e)
	{
		jQuery(this).catcomplete("search");
	});
}

function combo_autocomplete(field_id, source)
{
	var original_id 	= "";
	var original_text 	= "";

	// Attempt to remove any existing autocomplete handlers.
	try
	{
		jQuery("#" + field_id + "_autocomplete").catcomplete("destroy");
	}
	catch(e){}

	// Attach auto complete handler.
	jQuery("#" + field_id + "_autocomplete").catcomplete(
	{
		source: source,
		minLength: 0,
		delay: 0,
		autoFocus: false,
		create: function( event, ui )
		{
			original_id 	= jQuery("#" + field_id).val();
			original_text 	= jQuery("#" + field_id + "_autocomplete").val();
		},
		select: function( event, ui )
		{
			if(ui.item.id != "")
			{
				jQuery("#" + field_id + "_autocomplete").removeClass("ui-autocomplete-not-found").addClass("ui-autocomplete-found");
				jQuery("#" + field_id).val(ui.item.id);
			}
			else
			{
				jQuery("#" + field_id + "_autocomplete").removeClass("ui-autocomplete-found").addClass("ui-autocomplete-not-found");
				jQuery("#" + field_id).val("");
			}

			// Manually fire change event in case anything is listening.
			setTimeout(function()
			        	{
			           		jQuery("#" + field_id).change();
							jQuery("#" + field_id + "_autocomplete").change();
						}, 10);
		},
		close: function( event, ui )
		{
			// if(ui.item == null)
			// {
			// 	jQuery("#" + field_id + "_autocomplete").removeClass("ui-autocomplete-found").addClass("ui-autocomplete-not-found");
			// }
			// else
			// {
			// 	jQuery("#" + field_id + "_autocomplete").removeClass("ui-autocomplete-not-found").addClass("ui-autocomplete-found");
			// }

			// Manually fire change event in case anything is listening.
			setTimeout(function()
			        	{
			           		jQuery("#" + field_id).change();
							jQuery("#" + field_id + "_autocomplete").change();
						}, 10);
		},
		change: function( event, ui )
		{
			if(ui.item == null)
			{
				jQuery("#" + field_id + "_autocomplete").removeClass("ui-autocomplete-found").addClass("ui-autocomplete-not-found");
			}
			else
			{
				jQuery("#" + field_id + "_autocomplete").removeClass("ui-autocomplete-not-found").addClass("ui-autocomplete-found");
			}

			// Manually fire change event in case anything is listening.
			setTimeout(function()
			        	{
			           		jQuery("#" + field_id).change();
							jQuery("#" + field_id + "_autocomplete").change();
						}, 10);
		},
		search: function( event, ui )
		{
			// Temporarily wipe the ID so we can tell if anything was selected later.
			// jQuery("#" + field_id).val("").change();
		}
	});

	// Bind the text box so that it opens the menu automatically on focus.
	jQuery("#" + field_id + "_autocomplete").unbind("focus").focus(function(e)
	{
		jQuery(this).catcomplete("search");
	});
}
